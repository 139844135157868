import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="flex flex-col w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full h-fit max-md:px-[10px]">
        {/* Title */}
        <div className='flex m-2 text-[#31bfff] h-[80px] bg-white rounded-xl p-2 justify-center items-center text-center text-4xl max-md:text-3xl max-sm:text-2xl font-bold'>
          Privacy Policy
        </div>

        {/* Content */}
        <div className="bg-white p-5 rounded-xl shadow-md text-gray-700 text-lg max-md:text-base space-y-5">
          
          <p>Welcome to <strong>LECO (The Language, Education, and Culture Organization)</strong>. Your privacy is important to us, and we are committed to protecting your personal information.</p>

          <h2 className="text-2xl font-semibold text-[#31bfff]">1. No Collection of Personal Data</h2>
          <p>We do not collect, store, or share any personal information from our users. Our apps and website are designed to function without requiring personal data.</p>

          <h2 className="text-2xl font-semibold text-[#31bfff]">2. Children's Privacy</h2>
          <p>Our services are intended for users aged 6 and above. We do not knowingly collect data from children under the age of 6.</p>

          <h2 className="text-2xl font-semibold text-[#31bfff]">3. Usage Data</h2>
          <p>While we do not collect personal data, we may analyze anonymous usage data to improve the performance and user experience of our apps and website.</p>

          <h2 className="text-2xl font-semibold text-[#31bfff]">4. Third-Party Services</h2>
          <p>Our apps and website do not integrate third-party tracking services. However, if we use external links, they will be subject to the privacy policies of those respective websites.</p>

          <h2 className="text-2xl font-semibold text-[#31bfff]">5. Security</h2>
          <p>We prioritize security by ensuring that our services do not require sensitive personal information, reducing risks of data breaches.</p>

          <h2 className="text-2xl font-semibold text-[#31bfff]">6. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. Any changes will be posted on our website, and continued use of our services will constitute acceptance of these updates.</p>

          <h2 className="text-2xl font-semibold text-[#31bfff]">7. Contact Us</h2>
          <p>If you have any questions or concerns about this Privacy Policy, please contact us:</p>
          <p><strong>Email:</strong> <a href="mailto:support@leco.or.tz" className="text-[#31bfff] hover:underline">support@leco.or.tz</a></p>
          <p><strong>Website:</strong> <a href="https://www.leco.or.tz" className="text-[#31bfff] hover:underline">www.leco.or.tz</a></p>

        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;