import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_BASE_URL = "https://api.leco.or.tz/api/v1";
const API_STORAGE_URL = "https://api.leco.or.tz/storage";

const options = [
  { id: "1", title: "Lecture Notes", endpointPath: "/notes" }
];

const Learn = () => {
  const [data, setData] = useState([]); // Initialize with an array for mapping
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const results = [];
        await Promise.all(
          options.map(async (option) => {
            const response = await axios.get(`${API_BASE_URL}${option.endpointPath}?per_page=100`);
            results.push(...response.data.data); // Append the data array from the API response
          })
        );
        setData(results); // Set the combined results as an array
      } catch (err) {
        setError("Failed to fetch notes.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="flex flex-col w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full h-fit max-md:px-[10px]">
        {/* Title */}
        <div className='flex m-2 text-[#31bfff] h-[80px] bg-white rounded-xl p-2 justify-center items-center text-center text-4xl max-md:text-3xl max-sm:text-2xl font-bold'>
          Learning and Unlearning
        </div>
      </div>
      {/* Lecture Notes */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-fit py-4 w-full">
          <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffca05]'>Lecture Notes</div>
          {/* Videos */}
          <div className='flex flex-wrap w-full justify-center'>
            {loading ? (
              <div className="text-center text-blue-500">Loading...</div>
            ) : error ? (
              <div className="text-center text-red-500">{error}</div>
            ) : (
              <>
                {data.map((video) => (
                  <div className='m-2 max-md:w-full' key={video.id}>
                    <iframe
                      src={video.url || `${API_STORAGE_URL}/${video.file}`}
                      className='max-md:h-[280px] w-[340px] h-[220px] max-md:w-full'
                      title={video.name}
                      frameBorder="0"
                      allow="fullscreen"
                    ></iframe>
                    <div className='font-bold'>{video.name || 'Video Title'}</div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      {/* References */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-fit py-4 w-full">
          <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffca05]'>References</div>
        </div>
      </div>
      {/* Questions and Answers */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-fit py-4 w-full">
          <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffca05]'>Questions and Answers</div>
        </div>
      </div>
    </div>
  );
};

export default Learn;