import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="flex flex-col w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full h-fit max-md:px-[10px]">
        {/* Title */}
        <div className='flex m-2 text-[#31bfff] h-[80px] bg-white rounded-xl p-2 justify-center items-center text-center text-4xl max-md:text-3xl max-sm:text-2xl font-bold'>
          Terms & Conditions
        </div>
        
        {/* Content */}
        <div className="bg-white p-5 rounded-xl shadow-md text-gray-700 text-lg max-md:text-base space-y-5">
          
          <p>Welcome to <strong>LECO (The Language, Education, and Culture Organization)</strong>. By using our apps and website, you agree to the following terms and conditions. Please read them carefully.</p>

          <h2 className="text-2xl font-semibold text-[#31bfff]">1. Acceptance of Terms</h2>
          <p>By accessing or using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree, please do not use our services.</p>

          <h2 className="text-2xl font-semibold text-[#31bfff]">2. Use of Our Services</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li>Our apps and website are provided for <strong>educational and informational purposes</strong> only.</li>
            <li>You <strong>must be at least 6 years old</strong> to use our services.</li>
            <li>You agree <strong>not to misuse</strong> our services, including attempting to hack, modify, or disrupt the platform.</li>
            <li>Any content in our apps and website <strong>is owned by LECO</strong> and cannot be copied, distributed, or used without permission.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-[#31bfff]">3. No Collection of User Data</h2>
          <p>LECO does not collect, store, or share any personal data. Your privacy is our priority.</p>

          <h2 className="text-2xl font-semibold text-[#31bfff]">4. Intellectual Property Rights</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li>All content, including text, videos, images, and trademarks, belongs to <strong>LECO</strong> or is used with permission.</li>
            <li>You are <strong>not allowed</strong> to copy, modify, distribute, or use any content for commercial purposes without written consent from LECO.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-[#31bfff]">5. Limitation of Liability</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li>LECO provides its services <strong>"as is"</strong> without any guarantees or warranties.</li>
            <li>We are <strong>not responsible</strong> for any damages, losses, or issues resulting from the use of our apps or website.</li>
            <li>LECO is not liable for any <strong>errors, inaccuracies, or outdated information</strong> in the content provided.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-[#31bfff]">6. Changes to the Terms and Conditions</h2>
          <p>LECO reserves the right to update these Terms and Conditions at any time. Changes will be posted on our website. Your continued use of our services means you accept the updated terms.</p>

          <h2 className="text-2xl font-semibold text-[#31bfff]">7. Contact Us</h2>
          <p>If you have any questions or concerns about these Terms and Conditions, please contact us:</p>
          <p><strong>Email:</strong> <a href="mailto:support@leco.or.tz" className="text-[#31bfff] hover:underline">support@leco.or.tz</a></p>
          <p><strong>Website:</strong> <a href="https://www.leco.or.tz" className="text-[#31bfff] hover:underline">www.leco.or.tz</a></p>

        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;