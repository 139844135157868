import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Picture10, Picture9 } from '../assets';

const API_BASE_URL = "https://api.leco.or.tz/api/v1";
const API_STORAGE_URL = "https://api.leco.or.tz/storage";

const options = [
  { id: "1", title: "Lexical Lists & Dictionaries", endpointPath: "/lists" },
  { id: "2", title: "Grammars", endpointPath: "/grammas" },
  { id: "3", title: "LOT Project Occasional Papers", endpointPath: "/projects" },
  { id: "4", title: "Theses & Dissertations", endpointPath: "/theses" },
  { id: "5", title: "Maps, Facts & Figures", endpointPath: "/facts-figure" },
  { id: "6", title: "Socio-cultural Issues", endpointPath: "/social" },
  { id: "7", title: "Language in Education", endpointPath: "/language-education" },
  { id: "8", title: "Language and Technology", endpointPath: "/language-technology" },
  { id: "9", title: "Indigenous Knowledge", endpointPath: "/indigenous-knowledge" },
  { id: "10", title: "Language Description", endpointPath: "/language-description" },
];

const Documentation = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const results = {};
        await Promise.all(
          options.map(async (option) => {
            const response = await axios.get(`${API_BASE_URL}${option.endpointPath}?per_page=100`);
            results[option.id] = response.data.data; // Store data under option ID
          })
        );
        setData(results);
      } catch (err) {
        setError("Failed to fetch data for all options.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="flex flex-col w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full h-fit max-md:px-[10px]">
        {/* Title */}
        <div className='flex m-2 text-[#31bfff] h-[80px] bg-white rounded-xl p-2 justify-center items-center text-center text-4xl max-md:text-3xl max-sm:text-2xl font-bold'>Documentation</div>
      </div>
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="h-fit w-full">
          <div className='text-[25px] text-center max-md:text-[20px] font-bold text-[#ffca05]'>Languages of Tanzania (LOT) Project </div>
          <div className='text-justify'>The Languages of Tanzania (LOT) Project was a research endeavour conceived in the Department of Foreign Languages and Linguistics (DFLL) in the College of Humanities (CoHU) of the University of Dar es Salaam (UDSM). The project began in 2001 and ended in 2016. The research team was led by two professors, namely Henry R.T. Muzale and Josephat M. Rugemalira, in collaboration with the African Linguistics group at Göteborg University (Sweden), which was then headed by Prof. Kasten Legere and later by Prof. Laura Downing. The researchers came from DFLL, the Institute of Kiswahili Studies (IKS/TATAKI) at the University of Dar es Salaam and the Department of Oriental and African Studies at Göteborg University.</div>
          <div className='mt-3'>
            <div className='font-bold text-emerald-600'>Broad Objectives</div>
            <div className='text-justify'>LOT had two broad objectives, that is, to document the languages of Tanzania in various forms, i.e., grammars, dictionaries, a language atlas and oral literature publications, and to build the requisite capacity in language research and documentation.</div>
          </div>
          <div className='mt-3'>
            <div className='font-bold text-emerald-600'>Specific Objectives</div>
            <div className='text-justify font-medium'>The project’s specific objectives were:</div>
            <ol type="1" className='ml-7' style={{ listStyleType: 'decimal' }}>
              <li>To undertake studies on Tanzania’s ethnic community languages;</li>
              <li>To organise retreats for researchers to write manuscripts on the languages;</li>
              <li>To organise conferences for disseminating research findings and seminars for sensitising the Tanzanian people to the usefulness of the languages;</li>
              <li>To train researchers and research assistants in language documentation.</li>
            </ol>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full bg-white justify-center items-center">
        <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
          <div className="h-fit w-full">
            <div className='text-[25px] text-center max-md:text-[20px] font-bold text-[#ffca05]'>LOT Project Impact</div>
            <div className='flex gap-4 max-md:flex-col w-full justify-center items-center'>
              <img src={Picture9} className="w-[180px] h-full object-cover" />
              <div>
                <div className='text-justify mb-2'>LOT Project had a significant cumulative impact on publication and capacity building. It supported 64 (i.e., 49 male and 15 female) candidates in their MA linguistics studies. Most of the scholarship beneficiaries were later employed in higher learning institutions in the country, and several of them have already graduated with PhD qualifications and are thus able to conduct research to advance the project’s agenda.</div>
                <div className='text-justify mb-2'>The project also boasts 37 publications, namely 26 dictionaries (including the Tanzanian Sign Language Dictionary and a Dictionary of Plant Names and Functions in Haya), 5 grammars, 4 volumes of occasional papers in Linguistics, a language atlas and a collection of beautiful Swahili poems, Nakuomba, a book like no other, which not only educates, entertains, soothes and puzzles, but can also move you to tears at times. Several communities are now requesting to have publications produced in their languages.</div>
              </div>
            </div>
            <div className='flex gap-4 max-md:flex-col w-full justify-center items-center'>
              <div>
                <div className='font-bold text-emerald-600'>The Language Atlas of Tanzania</div>
                <div className='text-justify mb-2'>The Language Atlas of Tanzania (Atlasi ya Lugha za Tanzania) is a unique and interesting reference in terms of content and quality. Its uniqueness is evident in the highly impressive colour maps and the graphic charts it contains. The charts provide important information on the languages, their distribution across the country and the number of speakers per district. The introductory section gives a general sociolinguistic picture of Tanzania, as well as an analysis of how the languages are genetically related. The figures indicating the number of speakers, portray a picture of endangered languages that are threatened with extinction in the relatively near future.</div>
              </div>
              <img src={Picture10} className="w-[180px] h-full object-cover" />
            </div>
            <div>
              <div className='font-bold text-emerald-600'>Collaboration</div>
              <div className='text-justify mb-2'>The project collaborated with the Centre for Advanced Studies of African Society (CASAS) of Cape Town, South Africa, to publish the Harmonised Orthography of the Bantu Languages of Tanzania. It also collaborated with the African Union’s Academy of African Languages (ACALAN) to produce a language atlas for Africa.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        {options.map((option) => (
          <div key={option.id} className="bg-white h-fit py-4 w-full">
            <div alt={option.title} className="text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffca05]">
              {option.title}
            </div>
            {loading ? (
              <div className="text-center text-blue-500">Loading documents...</div>
            ) : error ? (
              <div className="text-center text-red-500">{error}</div>
            ) : (
              <ol type="1" className="ml-7" style={{ listStyleType: "decimal" }}>
                {data[option.id].map((doc) => (
                  <li key={doc.id} className="">
                    <a
                      className="text-blue-500 hover:font-bold"
                      href={`${API_STORAGE_URL}/${doc.file}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      alt={doc.name}
                    >
                      {doc.name}
                    </a>
                  </li>
                ))}
              </ol>
            )}
          </div>
        ))}
      </div>
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-fit py-4 w-full">
          <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffca05]'>Policies</div>
          <ol type="1" className='ml-7' style={{ listStyleType: 'decimal' }}>
            {/* <li className=''></li> */}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Documentation;